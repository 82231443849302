import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebase';
import '../style/jobmarquee.css';

const CotationMarquee = () => {
  const [jobAds, setJobAds] = useState([]);

  useEffect(() => {
    const jobAdsRef = ref(database, 'cotations/');
    const unsubscribe = onValue(jobAdsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setJobAds(Object.values(data));
      } else {
        setJobAds([]);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className='marquee-container'>
      <marquee>
        {jobAds.map((job, index) => (
          <p key={index}>
            <b>{job.company}: </b>
            <span>{job.description} - {job.title} <small className='text-secondary'>{job.dueDate}</small></span>
          </p>
        ))}
      </marquee>
    </div>
  );
};

export default CotationMarquee;
