import { useState } from "react";

const Payment = ()=>{
    const [amount, setAmount] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handlePayment = async () => {
    const response = await fetch('/pay', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        amount,
        phoneNumber,
        reference: 'subscription',
        description: 'Subscription Payment'
      })
    });

    const result = await response.json();
    console.log(result);
  };
    return(<>
     <div>
      <h1>Subscribe</h1>
      <input
        type="text"
        placeholder="Amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      <input
        type="text"
        placeholder="Phone Number"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
      />
      <button onClick={handlePayment}>Pay with Mpesa</button>
    </div>
    </>)
}
export default Payment