import { onValue, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { database } from '../firebase';

const ViewConcurso = () => {
    const { id } = useParams();
    const [concurso, setConcurso] = useState(null);

    useEffect(() => {
        const concursoRef = ref(database, `concursos/${id}`);
        onValue(concursoRef, (snapshot) => {
            setConcurso(snapshot.val());
        });
    }, [id]);

    if (!concurso) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container mt-4">
            <div className="card mb-4 shadow-sm">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-2 d-flex align-items-center">
                            <img src={concurso.logo} alt={`${concurso.organizer} logo`} className="img-fluid" />
                        </div>
                        <div className="col-md-10">
                            <h5 className="card-title">{concurso.title}</h5>
                            <h6 className="card-subtitle mb-2 text-muted">
                                {concurso.organizer} - {concurso.location}
                            </h6>
                            <p className="card-text">{concurso.description}</p>
                            <div className="d-flex justify-content-between">
                                <small className="text-muted">Publicado em: {concurso.publishedDate}</small>
                                {concurso.isExpired ? (
                                    <span className="badge bg-danger">Expirado</span>
                                ) : (
                                    <span className="badge bg-success">Ativo</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewConcurso;
