import React from 'react';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar-item">Feed</div>
      <div className="sidebar-item">Connections</div>
      <div className="sidebar-item">Groups</div>
      <div className="sidebar-item">Events</div>
      <div className="sidebar-item">Pages</div>
    </div>
  );
};

export default Sidebar;
