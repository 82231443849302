import React from 'react';
import { useParams } from 'react-router-dom';

const PostDetails = ({ feedData }) => {
  const { id } = useParams();
 
  const post = feedData.find((post) => post.id == id);


  console.log(post)

  if (!post) {
    return <div>Post não encontrado</div>;
  }

  return (
    <div className="post-details">
      <div className="post-header">
        <img src={post.companyPic} alt={post.user.name} className="post-avatar" />
        <div className="post-user-info">
          <h3>{post.company}</h3>
        </div>
      </div>
      <div className="post-content">
        <p>{post.content}</p>
        {post.image && <img src={post.image} alt="Post Content" className="post-image" />}
        {post.type === 'job' && (
          <div className="post-details-section">
            <p><strong>Localização:</strong> {post.details.location}</p>
            <p><strong>Categoria:</strong> {post.details.category}</p>
          </div>
        )}
        {post.type === 'cotation' && (
          <div className="post-details-section">
            <p><strong>Descrição:</strong> {post.details.title}</p>
            <p><strong>Data de Entrega:</strong> {post.details.dueDate}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostDetails;
