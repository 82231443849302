import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebase';
import CompanyCard from '../components/CompanyCard';
import '../style/explore.css';

const Explore = () => {
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const companiesRef = ref(database, 'company');
        onValue(companiesRef, (snapshot) => {
            const companiesData = snapshot.val();
            console.log(companiesData)
            if (companiesData) {
                const companiesArray = Object.values(companiesData);
                setCompanies(companiesArray);
            }
        });
    }, []);

    return (
        <div className='grid-cont'>
            <div className='rows'>
                {companies.map((company, index) => (
                    <div className='col-33' key={index}>
                        <CompanyCard 
                            logoUrl={company.logoUrl}
                            nome={company.nome}
                            provincia={company.provincia}
                            categoria={company.categoria}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Explore;
