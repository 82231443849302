import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth, database } from '../firebase';
import { onValue, ref } from 'firebase/database';

const LoginForm = ({ onClose }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(''); // Reset previous errors
    try {
      await signInWithEmailAndPassword(auth, email, password);
      onClose(); // Close the login form
      navigate('/'); // Navigate to home page
    } catch (error) {
      setError('Failed to log in. Please check your email and password.');
      console.error('Login error:', error);
    }
  };

  const handleGoogleSignIn = async () => {
    if (isPopupOpen) return; // Prevent multiple popups
    setIsPopupOpen(true);

    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);

      const user = auth.currentUser;
      localStorage.setItem('user', JSON.stringify(user));

      const companiesRef = ref(database, `company/${user.uid}`);
      onValue(companiesRef, (snapshot) => {
        if (snapshot.exists()) {
          const companyData = snapshot.val();
          localStorage.setItem('company', JSON.stringify(companyData));
        }
      });

      onClose(); // Close the login form
      navigate('/'); // Navigate to home page
    } catch (error) {
      if (error.code === 'auth/cancelled-popup-request') {
        console.warn('Popup canceled due to multiple requests');
      } else {
        setError('Failed to log in with Google.');
        console.error('Google login error:', error);
      }
    } finally {
      setIsPopupOpen(false);
    }
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input 
          type="email" 
          id="email" 
          name="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          required 
        />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <input 
          type="password" 
          id="password" 
          name="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          required 
        />
      </div>
      {error && <p className="error-message">{error}</p>}
      <button type="submit" className="login-button">Sign in</button>
      <div className="divider">
        <span>or</span>
      </div>
      <button 
        type="button" 
        className="google-button" 
        onClick={handleGoogleSignIn}
        disabled={isPopupOpen}
      >
        <img src="https://img.icons8.com/color/16/000000/google-logo.png" alt="Google logo" />
        {isPopupOpen ? 'Loading...' : 'Sign in with Google'}
      </button>
      <p className="signup-link">
        New to Connections? <a href="#">Join now</a>
      </p>
    </form>
  );
};

export default LoginForm;
