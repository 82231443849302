import '../../style/loader.css'
import '../../style/profile.css'; 

const ProfileLoader = () =>{
    return(<><div className="profile-container">
        {/* Cabeçalho do Perfil */}
        <div className="profile-header">
          <img
            src='default-photo-url'
            alt="Foto de Perfil"
            className="profile-picture bg-loader"
           
          />
          <div>
            <h1 className="profile-name bg-loader">{'A carregar'}</h1>
            <p className="profile-title bg-loader">{'A carregar'}, {'A carregar'}</p>
            <p className="profile-title bg-loader">{'A carregar'}</p>
          </div>
          <div className='details'>
            <p className='bg-loader'><small>{0} Visualizações</small></p>
            <p className='bg-loader'><small>{0} Seguidores</small></p>
            <p className='bg-loader'><small>{0} Seguindo</small></p>
          </div>
          
        </div>
        <div className="profile-section">
          <h2>Pedidos de Cotação</h2>
          <ul>
              <li className='bg-loader'>A carregar pedidos cotação.</li>
          </ul>
        </div>
        <div className="profile-section">
          <h2>Concursos Publicados</h2>
          <ul>
              <li className='bg-loader'>A carregar  concurso.</li>
          </ul>
        </div>
        <div className="profile-section">
          <h2>Fotos Publicadas</h2>
          <div className="published-photos">
          A carregar fotos
          </div>
          <div className="upload-photo">
            <input type="file"/>
            <button  className='btn btn-success'>Upload Nova Foto</button>
          </div>
        </div>
      </div></>)
}

export default ProfileLoader