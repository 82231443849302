import React, { useEffect, useState } from 'react';
import '../style/profile.css'; 
import Navbar from '../components/Navbar';
import { auth, database } from '../firebase';
import { ref, onValue, set, push, get } from 'firebase/database';
import { signOut } from 'firebase/auth';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { mUser } from '../utils/utils';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../style/story.css'
import ProfileLoader from '../components/loaders/ProfileLoader';
import StockAndServicesModal from '../components/StockAndServicesModal';
import { Facebook, Instagram, LinkedIn, Web, WebStories, WebStoriesOutlined } from '@mui/icons-material';
import { GlobalStyles } from '@mui/material';

const ProfileView = () => {


  const { id } = useParams();

  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [newPhoto, setNewPhoto] = useState(null);
  const [quotations, setQuotations] = useState([]);
  const [contests, setContests] = useState([]);
  const [publishedPhotos, setPublishedPhotos] = useState([]);
  const [newPublishedPhoto, setNewPublishedPhoto] = useState(null);
  const [loading, setLoading] = useState(true);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  useEffect(() => {
    if (id) {
      const companyRef = ref(database, `company/${id}`);
      const quotationsRef = ref(database, `quotations/${id}`);
      const contestsRef = ref(database, `contests/${id}`);
      const publishedPhotosRef = ref(database, `company/${id}/publishedPhotos`);
      get(companyRef).then((snapshot) => {
        if (snapshot.exists()) {
          setCompanyData(snapshot.val());
        } else {
          setCompanyData(null);
        }
        setLoading(false);
      }).catch((error) => {
        console.error('Error fetching company data: ', error);
        setLoading(false);
      });

      get(quotationsRef).then((snapshot) => {
        if (snapshot.exists()) {
          setQuotations(Object.values(snapshot.val()));
        } else {
          setQuotations([]);
        }
      }).catch((error) => {
        console.error('Error fetching quotations: ', error);
      });

      get(contestsRef).then((snapshot) => {
        if (snapshot.exists()) {
          setContests(Object.values(snapshot.val()));
        } else {
          setContests([]);
        }
      }).catch((error) => {
        console.error('Error fetching contests: ', error);
      });

      get(publishedPhotosRef).then((snapshot) => {
        if (snapshot.exists()) {
          setPublishedPhotos(Object.values(snapshot.val()));
        } else {
          setPublishedPhotos([]);
        }
      }).catch((error) => {
        console.error('Error fetching published photos: ', error);
      });
    } else {
      navigate('/auth');
    }
  }, [navigate]);

  if (loading) {
    return(
    <>
    <ProfileLoader/>
    </>
    )
  }
  return (
    <>
      <div className="profile-container">
        {/* Cabeçalho do Perfil */}
        <div className="profile-header">
          <img
            src={companyData?.logoUrl || 'default-photo-url'}
            alt="Foto de Perfil"
            className="profile-picture"
            onClick={() => setModalOpen(true)}
          />
          <div>
            <h1 className="profile-name">{companyData?.nome || 'Nome da Empresa'}</h1>
            <p className="profile-title">{companyData?.provincia || 'Província'}, {companyData?.endereco || 'Endereço'}</p>
            <p className="profile-title">{companyData?.contacto || 'Contato'}</p>
            <p className="profile-title"><a href=''>Visitar<Web/></a></p>
          </div>
          <div className='details'>
          <p><small><WebStories/></small></p>
            <p><small><LinkedIn/></small></p>
          <p><small><Instagram/></small></p>
            <p><small><Facebook/></small></p>
          </div>
          <div className='details'>
          <a className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">PEDIR COTACAO</a></div>
        </div>
  
    <StockAndServicesModal companyId={id}/>


        {/* Lista de Fotos Publicadas */}
        <div className="profile-section">
          <h2>Fotos Publicadas</h2>
          <div className="published-photos">
            {publishedPhotos.length > 0 ? (
              publishedPhotos.map((photo, index) => (
                <img key={index} src={photo} alt={`Foto publicada ${index + 1}`} className="published-photo" style={{width:'20%'}}/>
                
              ))
            ) : (
              <p>Nenhuma foto publicada encontrada.</p>
            )}
    
          </div>
       
        </div>
      </div>
    </>
  );
};

export default ProfileView;

