import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../style/hero.css';
import bg from '../images/divulgue.gif'

const Hero = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
    };

    return (
        <div className="hero-container">
            <Slider {...settings} className="ads-slider">
            <div className="ad-item">
                    <img 
                        src={bg}
                        alt="Banner 2"
                        className="ad-image"
                    />
                </div>
                <div className="ad-item">
                    <img 
                        src={bg}
                        alt="Banner 2"
                        className="ad-image"
                    />
                </div>
                <div className="ad-item">
                    <img 
                        src={bg}
                        alt="Banner 2"
                        className="ad-image"
                    />
                </div>
               
            </Slider>
        </div>
    );
}

export default Hero;
