// src/components/WelcomeSnackbar.js
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { mUser } from '../utils/utils';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const WelcomeSnackbar = ({ open, handleClose, mail }) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        Bem-vindo! {mail}
      </Alert>
    </Snackbar>
  );
};

export default WelcomeSnackbar;
