import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebase';
import { mUser } from '../utils/utils';
import '../style/aplication.css';

const CotationRequestList = () => {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const requestsRef = ref(database, 'cotations');
    const unsubscribe = onValue(requestsRef, (snapshot) => {
      const data = snapshot.val();
      const companyQuotations = [];
      if (data) {
        // Filter and map data to array

        Object.values(data).forEach((quotation) => {
          if (quotation.user === mUser.uid) {
            companyQuotations.push(quotation);
          }
        });

      } 
      setRequests(companyQuotations);
    }, (error) => {
      console.error("Error fetching data: ", error);
      setRequests([]);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="container mt-4">
      <h2>Pedidos de Cotação Emitidos</h2>
      {requests.length > 0 ? (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Empresa</th>
              <th>Notas</th>
              <th>Data</th>
              <th>Orcamento</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            {requests.map((request) => (
              <tr key={request.id}>
                <td>{request.company}</td>
                <td>{request.additionaComments}</td>
                <td>{request.dueDate}</td>
                <td>{request.maxValue}MT</td>
                <td><a href={`details/${request.id}`}>detalhes</a></td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Nenhum pedido de cotação encontrado.</p>
      )}
    </div>
  );
};

export default CotationRequestList;
