import React, { useEffect, useState } from 'react';
import { ref, push, set, onValue } from 'firebase/database';
import { database } from '../firebase';
import { mCompany, mUser } from '../utils/utils';

const StockAndServicesModal = ({ companyId, companyContact }) => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [notes, setNotes] = useState('');

  useEffect(() => {
    const productsRef = ref(database, 'products');
    const productsListener = onValue(productsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setProducts(Object.values(data));
      }
    });

    return () => {
      productsListener();
    };
  }, []);

  useEffect(() => {
    const combinedItems = [...products];
    const filtered = combinedItems.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.reference.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredItems(filtered);
  }, [searchTerm, products]);

  const handleQuantityChange = (item, quantity) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [item.reference]: quantity
    }));
  };

  const handleSelect = (item) => {
    const quantity = quantities[item.reference] || 1;
    setSelectedItems((prevItems) => [
      ...prevItems,
      { ...item, quantity }
    ]);
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [item.reference]: 1 
    }));
  };

  
  const handleSave = () => {
    const requestsRef = ref(database, 'quotation_requests');
    const newRequestRef = push(requestsRef);
    
    const requestData = {
      userId: mUser.uid,
      company: mCompany.nome,
      to: companyId,
      items: selectedItems,
      notes: notes,
      timestamp: Date.now(),
      status: 'pendente'
    };
  
    set(newRequestRef, requestData)
      .then(() => {
        console.log('Pedido de cotação salvo com sucesso!');
        setSelectedItems([]);
        setQuantities({});
        setNotes('');
        alert('Pedido de cotação enviado com sucesso!');

   
    })
      .catch((error) => {
        console.error('Erro ao salvar o pedido de cotação:', error);
      });
  };

  return (
    <div className="modal modal-xl fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">Stock & Serviços</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Procurar por referência ou nome"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <table className="table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Preço</th>
                  <th>Categoria</th>
                  <th>Quantidade</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {filteredItems.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.price} MT</td>
                    <td>{item.category}</td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        value={quantities[item.reference] || 1}
                        onChange={(e) => handleQuantityChange(item, e.target.value)}
                        min="1"
                      />
                    </td>
                    <td>
                      <button className="btn btn-primary btn-sm" onClick={() => handleSelect(item)}>Selecionar</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="form-group">
              <label htmlFor="notes">Notas:</label>
              <textarea
                id="notes"
                className="form-control"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary" onClick={handleSave}>Enviar Pedido</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockAndServicesModal;
