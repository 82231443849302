// src/pages/Home.js
import React from 'react';
import '../style/home.css';
import HeroApplication from '../components/HeroApplication';
import NavbarApplication from '../components/NavbarApplication';
import CotationMarquee from '../components/CotationMarquee';
import CotationRequestList from '../components/CotationRequestList';
import CotationInbox from '../components/CotationInbox';

const Aplication = () => {
  return (

      <>
         <CotationRequestList/>
         <CotationInbox/></>
    
  )
}
export default Aplication;