import Navbar from "./Navbar";
import RightSidebar from "./RightSidebar";
import Sidebar from "./Sidebar";

const MainLayout = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className='home' style={{float:'left', marginTop:'8%'}}>
        <div className="layout">
          <Sidebar />
          <main className="main-content">
            {children}
          </main>
          <RightSidebar />
        </div>
      </div>
    </>
  );
};

export default MainLayout;
