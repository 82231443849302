import React, { useState } from 'react';
import { ref, push, set } from 'firebase/database';
import { database } from '../../firebase';
import { mUser } from '../../utils/utils';

const ProductForm = () => {
  const [product, setProduct] = useState({
    name: '',
    reference: '',
    description: '',
    category: '',
    price: '',
    image: '',
    manufacturer: '',
    unit: '',
    technicalSpecifications: '',
    warranty: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validate form fields
    const fields = ["name", "reference", "description", "category", "price", "image", "manufacturer", "unit", "technicalSpecifications", "warranty"];
    for (const field of fields) {
      if (!product[field]) {
        alert(`Please fill in the ${field} field.`);
        return;
      }
    }

    const productsRef = ref(database, 'products');
    const newProductRef = push(productsRef);
    const newProduct = {
      ...product,
      company:mUser.uid,
      id: newProductRef.key,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    set(newProductRef, newProduct).then(() => {
      console.log('Produto adicionado com sucesso');
      setProduct({
        name: '',
        reference: '',
        description: '',
        category: '',
        price: '',
        image: '',
        manufacturer: '',
        unit: '',
        technicalSpecifications: '',
        warranty: '',
      });
    }).catch((error) => {
      console.error('Erro ao adicionar produto:', error);
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Nome</label>
        <input type="text" name="name" className="form-control" value={product.name} onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Referência</label>
        <input type="text" name="reference" className="form-control" value={product.reference} onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Descrição</label>
        <textarea name="description" className="form-control" value={product.description} onChange={handleChange} required></textarea>
      </div>
      <div className="form-group">
        <label>Categoria</label>
        <input type="text" name="category" className="form-control" value={product.category} onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Preço</label>
        <input type="number" name="price" className="form-control" value={product.price} onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>URL da Imagem</label>
        <input type="text" name="image" className="form-control" value={product.image} onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Fabricante</label>
        <input type="text" name="manufacturer" className="form-control" value={product.manufacturer} onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Unidade de Medida</label>
        <input type="text" name="unit" className="form-control" value={product.unit} onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Especificações Técnicas</label>
        <textarea name="technicalSpecifications" className="form-control" value={product.technicalSpecifications} onChange={handleChange} required></textarea>
      </div>
      <div className="form-group">
        <label>Garantia</label>
        <input type="text" name="warranty" className="form-control" value={product.warranty} onChange={handleChange} required />
      </div>
      <button type="submit" className="btn btn-primary">Adicionar Produto</button>
    </form>
  );
};

export default ProductForm;
