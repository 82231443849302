import { get, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react';
import { database } from '../firebase';
import { Link } from 'react-router-dom';

const Vagas = () => {
    const [feedData, setFeedData] = useState([]);

    useEffect(() => {
        const jobsRef = ref(database, 'jobs');
      
        get(jobsRef).then((snapshot) => {
            if (snapshot.exists()) {
                setFeedData(Object.values(snapshot.val()));
            } else {
                setFeedData([]);
            }
          }).catch((error) => {
            console.error('Error fetching quotations: ', error);
          });
        console.log(feedData)
    }, []);

    return (
        <div className="container mt-4">
            {feedData.map(vaga => (
                <div key={vaga.id} className="card mb-4 shadow-sm">
                      <Link to={`/job/${vaga.id}`} key={vaga.id} className="text-decoration-none">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-1 d-flex align-items-center">
                                <img src={vaga.companyLogo} alt={`${vaga.company} logo`} className="img-fluid" />
                            </div>
                            <div className="col-md-11">
                                <h5 className="card-title">{vaga.jobTitle} - {vaga.location}</h5>
                                <p className="card-subtitle mb-2 text-muted">
                                  {vaga.companyName} 
                                </p>
                                <div className="d-flex justify-content-between">
                                    <small className="text-muted">Publicado em: {vaga.postedAt}</small>
                                    {vaga.isExpired ? (
                                        <span className="badge bg-danger">Expirada</span>
                                    ) : (
                                        <span className="badge bg-success">Ativa</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    </Link> 
                </div>
            ))}
        </div>
    );
}

export default Vagas;
