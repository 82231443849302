import React, { useEffect, useState } from 'react';
import { get, onValue, ref } from 'firebase/database';
import { database } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import '../style/cotation.css';

const CotationList = () => {
    const [cotations, setCotations] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const cotationRef = ref(database, 'cotations/');
       
        get(cotationRef).then((snapshot) => {
            if (snapshot.exists()) {
                setCotations(Object.values(snapshot.val()));
            } else {
                setCotations([]);
            }
          }).catch((error) => {
            console.error('Error fetching quotations: ', error);
          });
    }, []);

    const handleShowDetails = (cotationId) => {
        navigate(`/cotation-details/${cotationId}`);
    };

    return (
      <>
        {cotations.map((cotation) => (
        <div  key={cotation.id} className="card mb-4 shadow-sm">
              <Link to={`/cotation/${cotation.id}`} key={cotation.id} className="text-decoration-none">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-1 d-flex align-items-center">
                        <img src={cotation.companyPic} alt={`${cotation.company} logo`} className="img-fluid" />
                    </div>
                    <div className="col-md-11">
                        <h5 className="card-title">{cotation.title} </h5>
                        <p className="card-subtitle mb-2 text-muted">
                        {cotation.company}
                        </p>
                        <div className="d-flex justify-content-between">
                        <small className="text-muted"><b>Proposta</b>: {cotation.maxValue}</small>
                        <small className="text-muted"> <b>Entrega</b>: {cotation.deliveryTerms}</small>
                        <small className="text-muted"><b>Limite</b>: {cotation.dueDate}</small>                         
                        </div>
                    </div>
                </div>
            </div>
            </Link> 
        </div>
        ))}
      </>  
    );
};

export default CotationList;
