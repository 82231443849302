import React from 'react';
import FeedItem from './FeedItem';
import '../style/feed.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Feed = ({ feedData, onDelete }) => {

  return (
       <div className='container'>
                      <div className='row'>

                  </div>
      {feedData.map((post) => (
        <FeedItem
          key={post.id}
          id={post.id}
          type={post.type}
          user={post.user}
          time={post.time}
          content={post.content}
          image={post.image}
          details={post.details}
          onDelete={(id) => onDelete(id, post.type)}
          onReport={(id) => console.log("Report ID:", id)}
        />
      ))}
    </div>
  );
};

export default Feed;
