import React, { useState, useEffect } from 'react';
import { auth, database, storage } from '../../firebase';
import { push, ref, set } from 'firebase/database';
import { uploadBytesResumable, getDownloadURL, ref as storageRef } from 'firebase/storage';
import { mUser, mCompany } from '../../utils/utils';

const CotationForm = () => {

  const [products, setProducts] = useState([
    {
      productDescription: '',
      productCode: '',
      quantity: '',
      unit: '',
      technicalSpecifications: '',
      packagingRequirements: '',
      deliveryTime: ''
    }
  ]);

  const [formData, setFormData] = useState({
    title:'',
    issueDate: '',
    dueDate: '',
    paymentTerms: '',
    deliveryTerms: '',
    deliveryAddress: '',
    maxValue: '',
    warranty: '',
    selectionCriteria: '',
    additionalComments: '',
    user: mUser ? mUser.uid : '',
    company: mCompany.nome,
    companyPic: mCompany.logoUrl
  });

  const [uploading, setUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleProductChange = (index, e) => {
    const { name, value } = e.target;
    const newProducts = [...products];
    newProducts[index][name] = value;
    setProducts(newProducts);
  };

  const handleAddProduct = () => {
    setProducts([
      ...products,
      {
        productDescription: '',
        productCode: '',
        quantity: '',
        unit: '',
        technicalSpecifications: '',
        packagingRequirements: '',
        deliveryTime: ''
      }
    ]);
  };

  const handleRemoveProduct = (index) => {
    const newProducts = [...products];
    newProducts.splice(index, 1);
    setProducts(newProducts);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setUploading(true);
    const uploadPromises = files.map((file) => {
      const storageReference = storageRef(storage, `uploads/${mUser.uid}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageReference, file);

      return uploadTask.then(() => getDownloadURL(storageReference));
    });

    Promise.all(uploadPromises)
      .then((downloadURLs) => {
        setUploadedFiles(downloadURLs);
        setUploading(false);
      })
      .catch((error) => {
        console.error('Erro ao fazer upload dos arquivos:', error);
        setUploading(false);
      });
  };

  
  const sendCotationToFirebase = (fullFormData) => {
    const cotationRef = ref(database, 'cotations/');
    const newCotationRef = push(cotationRef);
    const newCotationId = newCotationRef.key;

    // Incluir o ID gerado no objeto de dados da cotação
    const cotationWithId = { ...fullFormData, id: newCotationId };

    set(newCotationRef, cotationWithId)
      .then(() => {
        console.log('Pedido de cotação enviado com sucesso para o Firebase!');
      })
      .catch((error) => {
        console.error('Erro ao enviar pedido de cotação para o Firebase: ', error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const fullFormData = {
      ...formData,
      products,
      files: uploadedFiles
    };

    sendCotationToFirebase(fullFormData);
  };
  return (
    <div className="container mt-5 bg-white">
      <form onSubmit={handleSubmit}>
        <h3>Produto/Serviço</h3>
        <table className="table">
          <thead>
            <tr>
              <th>Descrição</th>
              <th>Código do Produto</th>
              <th>Quantidade Necessária</th>
              <th>Unidade de Medida</th>
              <th>Especificações Técnicas</th>
              <th>Condições de Embalagem</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    name="productDescription"
                    value={product.productDescription}
                    onChange={(e) => handleProductChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    name="productCode"
                    value={product.productCode}
                    onChange={(e) => handleProductChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    name="quantity"
                    value={product.quantity}
                    onChange={(e) => handleProductChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    name="unit"
                    value={product.unit}
                    onChange={(e) => handleProductChange(index, e)}
                  />
                </td>
                <td>
                  <textarea
                    className="form-control"
                    name="technicalSpecifications"
                    rows="2"
                    value={product.technicalSpecifications}
                    onChange={(e) => handleProductChange(index, e)}
                  ></textarea>
                </td>
                <td>
                  <textarea
                    className="form-control"
                    name="packagingRequirements"
                    rows="2"
                    value={product.packagingRequirements}
                    onChange={(e) => handleProductChange(index, e)}
                  ></textarea>
                </td>
                <td>
                  {index > 0 && (
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => handleRemoveProduct(index)}
                    >
                      Remover
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          type="button"
          className="btn btn-secondary mb-3"
          onClick={handleAddProduct}
        >
          Adicionar Produto
        </button>
        <h3>Informações Adicionais</h3>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Titulo</label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleFormChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="dueDate" className="form-label">Data Limite para Envio</label>
          <input
            type="date"
            className="form-control"
            id="dueDate"
            name="dueDate"
            value={formData.dueDate}
            onChange={handleFormChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="paymentTerms" className="form-label">Termos e Condições de Pagamento</label>
          <input
            type="text"
            className="form-control"
            id="paymentTerms"
            name="paymentTerms"
            value={formData.paymentTerms}
            onChange={handleFormChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="deliveryTerms" className="form-label">Condições de Entrega</label>
          <input
            type="text"
            className="form-control"
            id="deliveryTerms"
            name="deliveryTerms"
            value={formData.deliveryTerms}
            onChange={handleFormChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="deliveryAddress" className="form-label">Endereço de Entrega</label>
          <input
            type="text"
            className="form-control"
            id="deliveryAddress"
            name="deliveryAddress"
            value={formData.deliveryAddress}
            onChange={handleFormChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="maxValue" className="form-label">Valor Maximo</label>
          <input
            type="text"
            className="form-control"
            id="maxValue"
            name="maxValue"
            value={formData.maxValue}
            onChange={handleFormChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="warranty" className="form-label">Requisitos de Garantia</label>
          <textarea
            className="form-control"
            id="warranty"
            name="warranty"
            rows="3"
            value={formData.warranty}
            onChange={handleFormChange}
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="selectionCriteria" className="form-label">Critérios de Seleção</label>
          <textarea
            className="form-control"
            id="selectionCriteria"
            name="selectionCriteria"
            rows="3"
            value={formData.selectionCriteria}
            onChange={handleFormChange}
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="additionalComments" className="form-label">Comentários Adicionais</label>
          <textarea
            className="form-control"
            id="additionalComments"
            name="additionalComments"
            rows="3"
            value={formData.additionalComments}
            onChange={handleFormChange}
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="documents" className="form-label">Documentos</label>
          <input
            type="file"
            className="form-control"
            id="documents"
            name="documents"
            multiple
            onChange={handleFileChange}
          />
          {uploading && <p>Fazendo upload...</p>}
        </div>
        <button type="submit" className="btn btn-primary">Enviar Pedido de Cotação</button>
      </form>
    </div>
  );
};

export default CotationForm;
