import React from 'react';
import '../style/company.css';
import logo from '../images/bg.png';

const CompanyItem = ({ id,logoUrl, nome, provincia, categoria }) => {
  return (
    <a href={`company/${id}`} className='text-dark' style={{textDecoration:'none'}}>
    <div className="company-item">
      <img src={logoUrl} alt={nome} className="company-logo" />
      <div className="company-info">
        <span className="company-name">{nome}</span>
        <span className="company-details">{provincia} · {categoria}</span>
      </div>
    </div>
    </a>
  );
};

const CompanyList = ({ companies }) => {
  return (
    <div className="company-list-container">
      <div className="company-list">
        {companies.map((company, index) => (
          <CompanyItem
          id={company.id}
            key={index}
            logoUrl={company.logoUrl || logo} // Use default logo if none provided
            nome={company.nome}
            provincia={company.provincia}
            categoria={company.categoria}
          />
        ))}
      </div>
      <div className="more">
        <a href="#">Ver todas</a>
      </div>
    </div>
  );
};

export default CompanyList;
