// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import {getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDdWDE9sGuIBBfVrdpiUuoaKJEDviDmSaQ",
  authDomain: "connectiopos.firebaseapp.com",
  databaseURL: "https://connectiopos-default-rtdb.firebaseio.com",
  projectId: "connectiopos",
  storageBucket: "connectiopos.appspot.com",
  messagingSenderId: "1083339114910",
  appId: "1:1083339114910:web:3b2188a57862888ddb6e8f",
  measurementId: "G-8GTHHH8VKN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const database = getDatabase();
const storage = getStorage()

export { auth, database, storage };