// src/pages/AuthPage.js
import React, { useState } from 'react';
import LoginForm from '../components/LoginForm';
import '../style/auth.css';

const AuthPage = () => {
  const [isOpen, setIsOpen] = useState(true); // Inicialmente o modal está aberto

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className={`auth-page ${isOpen ? 'open' : 'closed'}`}>
      {isOpen && (
        <div className="auth-container">
          <h1 className="auth-title">Sign in</h1>
          <LoginForm onClose={handleClose} />
        </div>
      )}
    </div>
  );
};

export default AuthPage;
