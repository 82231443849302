import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../style/story.css';
import { onValue, ref } from 'firebase/database';
import { database } from '../firebase';

const CompanyStorie = () => {
    const [stories, setStories] = useState([]);
    useEffect(() => {
        const companiesRef = ref(database, 'company');
        onValue(companiesRef, (snapshot) => {
            const companiesData = snapshot.val();
            if (companiesData) {
                const companiesArray = Object.values(companiesData);
                setStories(companiesArray);
            }
        });
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          }
        ]
    };

    return (
        <div className="story-list">
            <Slider {...settings} className="ads-slider">
                {stories.map((story, index) => (
                    <div className="story owl-carousel owl-theme" key={index}>
                        <a href={`company/${story.id}`}>
                            <div className="story-item item" style={{ backgroundImage: `url(${story.img})` }}>
                                <img src={story.logoUrl} className="rounded" alt={story.nome} />
                                <span>{story.nome}<span></span></span>
                            </div>
                        </a>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default CompanyStorie;
