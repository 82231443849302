import Navbar from "./Navbar";
import NavbarApplication from "./NavbarApplication";
import RightSidebar from "./RightSidebar";
import Sidebar from "./Sidebar";

const AlternateLayout = ({ children }) => {
  return (
    <>
        <NavbarApplication />
        <div className='home'>
        <div className="layout">
          <Sidebar />
          <main className="main-content">
            {children}
          </main>
          <RightSidebar />
        </div>
      </div>
    </>
  );
};

export default AlternateLayout;
