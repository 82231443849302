import React from 'react';
import '../style/company.css'; // Ensure this path is correct
import logo from '../images/bg.png'; // Default logo

const CompanyCard = ({ logoUrl = logo, nome, provincia, categoria }) => {
  return (
    <div className="company-card">
      <div className='foto-de-capa'></div>
      <img src={logoUrl} alt="Logo da Empresa" className="company-logo-2" style={{borderRadius:'0'}}/>
      <div className="company-info">
        <span className="company-name">{nome}</span>
       <p> <span className="company-details">{provincia} · {categoria}</span></p>
        <div>
          <button className="btn btn-outline-secondary">Visualizar</button>
        </div>
      </div>
    </div>
  );
};

export default CompanyCard;
