import { onValue, ref, push } from 'firebase/database';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { database } from '../firebase';

const ViewJob = () => {
    const { id } = useParams();
    const [job, setJob] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [resume, setResume] = useState('');
    const [submissionStatus, setSubmissionStatus] = useState('');

    useEffect(() => {
        const jobRef = ref(database, `jobs/${id}`);
        onValue(jobRef, (snapshot) => {
            setJob(snapshot.val());
        });
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const applicationsRef = ref(database, `applications/${id}`);
        const newApplication = {
            name,
            email,
            resume,
            appliedAt: new Date().toISOString(),
        };
        push(applicationsRef, newApplication)
            .then(() => {
                setSubmissionStatus('Sua candidatura foi enviada com sucesso!');
                setName('');
                setEmail('');
                setResume('');
            })
            .catch((error) => {
                setSubmissionStatus('Erro ao enviar a candidatura. Tente novamente.');
                console.error('Erro ao enviar a candidatura:', error);
            });
    };

    const handleAnalyze = () => {
        // Implemente a lógica para chamar uma API de IA aqui
        alert('Analisar com IA clicado!');
    };

    if (!job) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container mt-4">
            <button 
                onClick={handleAnalyze} 
                className="btn btn-info position-fixed top-0 start-0 m-3"
            >
                Analisar com IA
            </button>

            <div className="card mb-4 shadow-sm">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-2 d-flex align-items-center">
                            <img src={job.companyLogo} alt={`${job.companyName} logo`} className="img-fluid" />
                        </div>
                        <div className="col-md-10">
                            <h5 className="card-title">{job.jobTitle}</h5>
                            <h6 className="card-subtitle mb-2 text-muted">
                                {job.companyName} - {job.location}
                            </h6>
                            <div className="card-text" dangerouslySetInnerHTML={{ __html: job.description }} />
                            <div className="d-flex justify-content-between">
                                <small className="text-muted">Publicado em: {new Date(job.postedAt).toLocaleDateString()}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {!job.isExpired && (
                <div className="mt-4">
                    <h4>Submeta sua candidatura</h4>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Nome</label>
                            <input
                                type="text"
                                id="name"
                                className="form-control"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input
                                type="email"
                                id="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="resume" className="form-label">Currículo</label>
                            <textarea
                                id="resume"
                                className="form-control"
                                value={resume}
                                onChange={(e) => setResume(e.target.value)}
                                rows="4"
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">Enviar Candidatura</button>
                    </form>
                    {submissionStatus && <div className="mt-3 alert alert-info">{submissionStatus}</div>}
                </div>
            )}
        </div>
    );
}
export default ViewJob;
