import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebase'; // Make sure this path is correct
import CompanyList from './CompanyList';

const RightSidebar = () => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const companiesRef = ref(database, 'company');
    const unsubscribe = onValue(companiesRef, (snapshot) => {
      const companiesData = snapshot.val();
      if (companiesData) {
        const companiesArray = Object.values(companiesData);
        setCompanies(companiesArray);
      }
    });
    
    return () => unsubscribe();
  }, []);

  return (
    <div className="right-sidebar">
      <div className="sidebar-section">
        <h6>Empresas</h6>
        <CompanyList companies={companies} />
      </div>
    </div>
  );
};

export default RightSidebar;
