import React, { useEffect, useState } from 'react';
import '../style/nav.css';
import logo from '../images/bg.png';
import { auth } from '../firebase';
import { Apps, Home, LocationCity, Newspaper, Topic, Work } from '@mui/icons-material';

const Navbar = () => {

  return (
    <div className='navbar bg'>
      <a href='/'>
        <div className='logo'>
        <img src={logo} alt='Logo' className='my-logo'/>
        </div>
      </a>
      <div className='nav'>

      <a href='/' className='text-center'><Home /><p>INICIO</p></a>
      <a href='/vagas' className='text-center'>< Work/><p>VAGAS</p></a>
      <a href='/cotacoes' className='text-center'><Topic /><p>COTAÇÕES</p></a>
      <a href='/app' className='text-center'><Apps /><p>APP</p></a>
      <a href='/perfil'><i className="bi bi-person-circle" style={{ fontSize: '16pt' }}></i><p>PERFIL</p></a>
      </div>
    </div>
  );
};

export default Navbar;
