import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { ref, push, set } from 'firebase/database';
import { database } from '../../firebase';
import { mCompany } from '../../utils/utils';

const ExcelUpload = () => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      setData(json);
      uploadToFirebase(json);
    };
    reader.readAsBinaryString(file);
  };

  const uploadToFirebase = (data) => {
    data.forEach((item) => {
      const newProductRef = push(ref(database, 'products'));

      const productData = {
        ...item,
        company: mCompany.id || 'unknown',
        createdAt: new Date().toISOString(),
        id: newProductRef.key,
        image: item.Image || ' ',
        updatedAt: new Date().toISOString(),
      }
      set(newProductRef, productData)
        .then(() => {
          console.log('Product saved successfully!');
        })
        .catch((error) => {
          console.error('Error saving product:', error);
        });
    });

    alert('Data imported successfully!');
  };

  return (
  
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Carregue o ficheiro excel padrao</h4>
            </div>
            <div className="card-body">
              <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
              <button className="btn btn-primary mt-3" onClick={handleFileUpload}>Upload</button>
              {data.length > 0 && (
                <div className="mt-3">
                  <h3>Preview</h3>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        {Object.keys(data[0]).map((key) => (
                          <th key={key}>{key}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((row, index) => (
                        <tr key={index}>
                          {Object.values(row).map((value, i) => (
                            <td key={i}>{value}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
  );
};

export default ExcelUpload;
