import React from 'react';
import '../style/nav.css';
import logo from '../images/bg.png';
import { auth } from '../firebase';
import HomeIcon from '@mui/icons-material/Home';
import PublishIcon from '@mui/icons-material/Publish';
import WorkIcon from '@mui/icons-material/Work';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';

const NavbarApplication = () => {
  var company = JSON.parse(localStorage.getItem('company'));

  const user = auth.currentUser;

  return (
    <div className='navbar bg'>
      <a href='/'>
        <div className='logo'>
          <img src={logo} alt='Logo' />
        </div>
      </a>
      <div className='nav text-white'>
        <a href='/doc'><PublishIcon /> EMITIR</a>
        <div className="dropdown">
          <a className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <WorkIcon /> 
          </a>
          <ul className="dropdown-menu bg">
            <li><a className="dropdown-item" href="/publicaVaga">Publicar vaga</a></li>
            <li><a className="dropdown-item" href="/minhasVagas">Vagas publicadas</a></li>
          </ul>
        </div>
        <div className="dropdown">
          <a className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <PublishIcon /> IMPORTAR
          </a>
          <ul className="dropdown-menu bg">
            <li><a className="dropdown-item" href="/servicesForm">Cadastrar Artigos & Serviços</a></li>
            <li><a className="dropdown-item" href="/importXls">Importar Artigos & Serviços</a></li>
          </ul>
        </div>
        <a href='settings'><SettingsIcon /> CONFIGURAR</a>
        <a><PersonIcon style={{ fontSize: '16pt' }} /></a>
      </div>
    </div>
  )
}

export default NavbarApplication;
