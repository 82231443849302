import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, set } from 'firebase/database';
import { auth, database } from '../firebase';
import { getDownloadURL, getStorage, ref as storageRef, uploadBytes } from 'firebase/storage';
import Navbar from './Navbar';
import { mUser } from '../utils/utils';
import UserProfileCard from './UserProfileCard';

const CompanyDataForm = () => {
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState({
    nome: '',
    nuit: '',
    contacto: '',
    email: '',
    endereco: '',
    provincia: '',
    responsavel: '',
    senha: '',
    logo: null, 
    subscription: {
      nextPaymentDate: new Date(Date.now() + 15 * 24 * 60 * 60 * 1000).toISOString(), 
      startDate: new Date().toISOString(),
      status: true
    }
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    const newValue = type === 'file' ? files[0] : value; 
    setCompanyData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = auth.currentUser;

      if (user) {
        let logoUrl = '';
        
        if (companyData.logo) {
          const storage = getStorage();
          const fileRef = storageRef(storage, `logos/${user.uid}`);
          await uploadBytes(fileRef, companyData.logo);
          logoUrl = await getDownloadURL(fileRef);
        }

        const dataWithLogoUrl = {
          ...companyData,
          id:user.uid,
          logoUrl: logoUrl,
        };

        const companyRef = ref(database, 'company/' + user.uid);
        await set(companyRef, dataWithLogoUrl);

        navigate('/perfil');
      }
    } catch (error) {
    }
  };

  return (
    <div id='home'>
    <div className='home'>
      <Navbar />
      <div className='grid-co '>
        <div className='grid-half-70 bg-white'>
        <div className="container mt-5">
      <form className="company-data-form" onSubmit={handleSubmit}>
        <h5 className="mb-4">Complete seu perfil(45%)</h5>
        <div className="form-group">
          <label htmlFor="nome">Nome da Empresa</label>
          <input 
            type="text" 
            className="form-control" 
            id="nome" 
            name="nome" 
            value={companyData.nome} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="nuit">NUIT</label>
          <input 
            type="text" 
            className="form-control" 
            id="nuit" 
            name="nuit" 
            value={companyData.nuit} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="contacto">Contacto</label>
          <input 
            type="text" 
            className="form-control" 
            id="contacto" 
            name="contacto" 
            value={companyData.contacto} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input 
            type="email" 
            className="form-control" 
            id="email" 
            name="email" 
            value={companyData.email} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="endereco">Endereço</label>
          <input 
            type="text" 
            className="form-control" 
            id="endereco" 
            name="endereco" 
            value={companyData.endereco} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="provincia">Província</label>
          <input 
            type="text" 
            className="form-control" 
            id="provincia" 
            name="provincia" 
            value={companyData.provincia} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="responsavel">Responsável</label>
          <input 
            type="text" 
            className="form-control" 
            id="responsavel" 
            name="responsavel" 
            value={companyData.responsavel} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="senha">Senha</label>
          <input 
            type="password" 
            className="form-control" 
            id="senha" 
            name="senha" 
            value={companyData.senha} 
            onChange={handleChange} 
            required 
          />
        </div>
        <br />
        <div className="form-group">
          <input 
            type="file" 
            className="form-control-file" 
            id="logo" 
            name="logo" 
            onChange={handleChange} 
            accept="image/*" 
            required 
          />
        </div><br />
        <button type="submit" className="btn btn-primary">Finalizar</button>
      </form>
    </div>
        </div>
    </div>
  </div>
  </div>
   
  );
};

export default CompanyDataForm;
