import React, { useEffect, useState } from 'react';
import '../style/profile.css'; 
import Navbar from '../components/Navbar';
import { auth, database } from '../firebase';
import { ref, onValue, set, push, get } from 'firebase/database';
import { signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import ProfileLoader from '../components/loaders/ProfileLoader';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { mUser } from '../utils/utils';
import { Web } from '@mui/icons-material';

const ProfilePage = () => {
  const navigate = useNavigate();
  const [mCompany, setmCompany] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [newPhotos, setNewPhotos] = useState([]);
  const [quotations, setQuotations] = useState([]);
  const [contests, setContests] = useState([]);
  const [publishedPhotos, setPublishedPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const user = mUser.uid;

  // Adicione estados para redes sociais e website
  const [social, setSocial] = useState({
    facebook: '',
    linkedin: '',
    instagram: '',
    website: ''
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/');
      localStorage.setItem('company', JSON.stringify([]));
      localStorage.setItem('user', JSON.stringify(''));
    }).catch((error) => {
      console.error('Error signing out: ', error);
    });
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setNewPhotos(files);
  };

  const handleSavePublishedPhotos = () => {
    if (newPhotos.length > 0) {
      const storage = getStorage();

      newPhotos.forEach(photo => {
        const fileRef = storageRef(storage, `published/${user}/${photo.name}`);
        const uploadTask = uploadBytesResumable(fileRef, photo);

        uploadTask.on('state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
            setSnackbarOpen(true);
          },
          (error) => {
            console.error('Error uploading photo: ', error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              const newPhotoRef = push(ref(database, `company/${user}/publishedPhotos`));
              set(newPhotoRef, url).then(() => {
                setUploadSuccess(true);
              });
            }).catch((error) => {
              console.error('Error getting photo URL: ', error);
            });
          }
        );
      });
    }
  };

  useEffect(() => {
    if (mUser) {
      const companyRef = ref(database, `company/${user}`);
      const quotationsRef = ref(database, `cotations/`);
      const contestsRef = ref(database, `contests/${user}`);
      const publishedPhotosRef = ref(database, `company/${user}/publishedPhotos`);
      const socialRef = ref(database, `company/${user}/social`);

      get(companyRef).then((snapshot) => {
        if (snapshot.exists()) {
          setmCompany(snapshot.val());
        } else {
          navigate('/setup');
          setmCompany(null);
        }
        setLoading(false);
      }).catch((error) => {
        console.error('Error fetching company data: ', error);
        setLoading(false);
      });

      get(quotationsRef).then((snapshot) => {
        if (snapshot.exists()) {
          setQuotations(Object.values(snapshot.val()));
        } else {
          setQuotations([]);
        }
      }).catch((error) => {
        console.error('Error fetching quotations: ', error);
      });

      get(contestsRef).then((snapshot) => {
        if (snapshot.exists()) {
          setContests(Object.values(snapshot.val()));
        } else {
          setContests([]);
        }
      }).catch((error) => {
        console.error('Error fetching contests: ', error);
      });

      get(publishedPhotosRef).then((snapshot) => {
        if (snapshot.exists()) {
          setPublishedPhotos(Object.values(snapshot.val()));
        } else {
          setPublishedPhotos([]);
        }
      }).catch((error) => {
        console.error('Error fetching published photos: ', error);
      });

      get(socialRef).then((snapshot) => {
        if (snapshot.exists()) {
          setSocial(snapshot.val());
        } else {
          setSocial({
            facebook: '',
            linkedin: '',
            instagram: '',
            website: ''
          });
        }
      }).catch((error) => {
        console.error('Error fetching social data: ', error);
      });

    } else {
      navigate('/auth');
    }
  }, [navigate]);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
    return(
      <>
        <ProfileLoader/>
      </>
    )
  }

  return (
    <>
      <div className="profile-container">
        <div className="profile-header">
          <img
            src={mCompany.logoUrl || 'default-photo-url'}
            alt="Foto de Perfil"
            className="profile-picture"
            onClick={() => setModalOpen(true)}
          />
          <div>
            <h1 className="profile-name">{mCompany?.nome || 'Nome da Empresa'}</h1>
            <p className="profile-title">{mCompany?.provincia || 'Província'}, {mCompany?.endereco || 'Endereço'}</p>
            <p className="profile-title">{mCompany?.contacto || 'Contato'}</p>
          </div>
          <div className='details'>
            {social.facebook && (
              <a href={social.facebook} target="_blank" rel="noopener noreferrer">
                <FacebookIcon />
              </a>
            )}
            {social.linkedin && (
              <a href={social.linkedin} target="_blank" rel="noopener noreferrer">
                <LinkedInIcon />
              </a>
            )}
            {social.instagram && (
              <a href={social.instagram} target="_blank" rel="noopener noreferrer">
                <InstagramIcon />
              </a>
            )}
            {social.website && (
              <a href={social.website} target="_blank" rel="noopener noreferrer">
                <Web/>
              </a>
            )}
          </div>
          <button className='logout-btn btn btn-danger' onClick={handleLogout}>Terminar Sessão</button>
          <Link to="/edit-profile" className="btn btn-primary">Editar Perfil</Link>
      
        </div>

        {/* Lista de Pedidos de Cotação */}
        <div className="profile-section">
          <h2>Pedidos de Cotação</h2>
          <ul>
            {quotations.length > 0 ? (
              quotations.map((quotation, index) => (
                <li key={index}>{quotation.dueDate}</li>
              ))
            ) : (
              <li>Nenhum pedido de cotação encontrado.</li>
            )}
          </ul>
        </div>

        {/* Lista de Concursos */}
        <div className="profile-section">
          <h2>Concursos Publicados</h2>
          <ul>
            {contests.length > 0 ? (
              contests.map((contest, index) => (
                <li key={index}>{contest.title}</li>
              ))
            ) : (
              <li>Nenhum concurso encontrado.</li>
            )}
          </ul>
        </div>

        {/* Lista de Fotos Publicadas */}
        <div className="profile-section">
          <h2>Fotos Publicadas</h2>
          <div className="published-photos">
            {publishedPhotos.length > 0 ? (
              publishedPhotos.map((photo, index) => (
                <img key={index} src={photo} alt={`Foto publicada ${index + 1}`} className="published-photo" style={{width:'20%'}}/>
              ))
            ) : (
              <p>Nenhuma foto publicada encontrada.</p>
            )}
          </div>
          <div className="upload-photo">
            <input type="file" multiple onChange={handleFileChange} />
            <button onClick={handleSavePublishedPhotos} className='btn btn-success'>Upload Novas Fotos</button>
          </div>
        </div>
      </div>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={uploadSuccess ? "success" : "info"} sx={{ width: '100%' }}>
          {uploadSuccess ? "Fotos carregadas com sucesso!" : `Progresso do upload: ${Math.round(uploadProgress)}%`}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProfilePage;
