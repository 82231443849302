import React, { useEffect, useState, createContext, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import './App.css';
import './style/home.css';
import { auth, database } from './firebase';
import { ref, onValue } from 'firebase/database';
import WelcomeSnackbar from './components/WelcomeSnackbar';
import MainLayout from './components/MainLayout';
import ProfilePage from './pages/ProfilePage';
import Aplication from './pages/Aplication';
import AuthPage from './pages/AuthPage';
import AlternateLayout from './components/AlternateLayout';
import CotationMarquee from './components/CotationMarquee';
import ProfileView from './pages/ProfileView';
import Explore from './pages/Explore';
import CotationListPage from './pages/CotationListPage';
import UploadJob from './components/forms/UploadJob';
import NavBarPost from './components/navBarPost';
import CotationForm from './components/forms/CotationForm';
import CompanyDataForm from './components/CompanyDataForm';
import ProductForm from './components/forms/ProductForm';
import ExcelUpload from './components/forms/ExcelUploadForm';
import MyVagas from './pages/MyVagas';
import PostDetails from './pages/PostPage';
import Vagas from './pages/Vagas';
import ViewJob from './pages/ViewJob';
import ViewConcurso from './pages/ViewConcurso';
import Concursos from './pages/Concurso';
import CotationDetails from './pages/CotationDetails';
import Payment from './pages/Payment';
import EditProfilePage from './pages/EditProfilePage';
import SendSMS from './pages/SendSMS';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        const isWelcomeMessageShown = localStorage.getItem(`welcomeMessageShown-${user.email}`);
        if (!isWelcomeMessageShown) {
          setOpenSnackbar(true);
          localStorage.setItem(`welcomeMessageShown-${user.email}`, 'true');
        }
      } else {
        localStorage.removeItem('welcomeMessageShown');
        navigate('/auth');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <AuthContext.Provider value={{ user }}>
      {children}
      <WelcomeSnackbar open={openSnackbar} handleClose={handleCloseSnackbar} />
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

const App = () => {
  const location = useLocation();
  const [cotations, setCotations] = useState([]);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const cotationsRef = ref(database, 'cotations');
    onValue(cotationsRef, (snapshot) => {
      const data = snapshot.val();
      setCotations(data ? Object.values(data) : []);
    });

    const jobsRef = ref(database, 'jobs');
    onValue(jobsRef, (snapshot) => {
      const data = snapshot.val();
      setJobs(data ? Object.values(data) : []);
    });
  }, []);

  const getLayout = () => {
    if (location.pathname.startsWith('/app')) {
      return AlternateLayout;
    }
    return MainLayout;
  };

  const Layout = getLayout();
  
  return (
    <Layout>
      <CotationMarquee />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/perfil" element={<ProfilePage />} />
        <Route path="/app" element={<Aplication />} />
        <Route path="/company/:id" element={<ProfileView />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/cotacoes" element={<CotationListPage />} />
        <Route path="/publicaVaga" element={<UploadJob />} />
        <Route path="/doc" element={<CotationForm />} />
        <Route path="/setup" element={<CompanyDataForm />} />
        <Route path="/servicesForm" element={<ProductForm />} />
        <Route path="/importXls" element={<ExcelUpload />} />
        <Route path="/minhasVagas" element={<MyVagas />} />
        <Route path="/vagas" element={<Vagas />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/job/:id" element={<ViewJob/>} />
        <Route path="/send-sms" element={<SendSMS/>} />
        <Route path="/concursos" element={<Concursos/>} />
        <Route path="/concurso/:id" element={<ViewConcurso/>} />
        <Route path="/cotation/:id" element={<CotationDetails/>} />
        <Route path="/edit-profile" element={<EditProfilePage />} />
        <Route path="/post/:id" element={<PostDetails feedData={[...cotations, ...jobs]} />} />
      </Routes>
    </Layout>
  );
};
const AppWrapper = () => {
  return (
    <Router>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Router>
  );
};

export default AppWrapper;
