import React, { useRef, useState } from 'react';
import { TextField, Button, Typography, MenuItem } from '@mui/material';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { ref as databaseRef, push, set } from 'firebase/database';
import { storage, database } from '../../firebase';
import '../../style/uploadJob.css';
import { mCompany, mUser } from '../../utils/utils';
import EditorQuill from '../../utils/editor';

const categories = ['Tecnologia', 'Saúde', 'Educação', 'Finanças', 'Marketing'];

const UploadJob = () => {
  const [jobTitle, setJobTitle] = useState('');
  const [location, setLocation] = useState('');
  const [category, setCategory] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [description, setDescription] = useState('');

  const quillRef = useRef(null);

  const handleFileUpload = (file, setFileUrl) => {
    return new Promise((resolve, reject) => {
      if (file) {
        const fileRef = storageRef(storage, `uploads/${file.name}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
          },
          (error) => {
            console.error('Upload failed:', error);
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setFileUrl(downloadURL);
              resolve(downloadURL);
            });
          }
        );
      } else {
        resolve(null);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    try {
      const attachmentUrl = await handleFileUpload(attachment, (url) => url);
      const photoUrl = await handleFileUpload(photo, (url) => url);

      const jobsRef = databaseRef(database, 'jobs');
      const newJobRef = push(jobsRef);
      const jobData = {
        jobTitle,
        companyName: mCompany.nome,
        companyId: mUser.uid,
        companyLogo: mCompany.logoUrl,
        description,
        location,
        category,
        attachmentUrl,
        photoUrl,
        postedAt: new Date().toISOString(),
        id: newJobRef.key, // Salvando o ID gerado no banco de dados
      };

      await set(newJobRef, jobData);

      alert('Job posted successfully!');
      setJobTitle('');
      setDescription('');
      setLocation('');
      setCategory('');
      setAttachment(null);
      setPhoto(null);
    } catch (error) {
      console.error('Error posting job:', error);
      alert('Failed to post job.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="upload-job-container">
      <Typography variant="h4" className="upload-job-header">
        Postar Vaga
      </Typography>
      <form className="upload-job-form" onSubmit={handleSubmit}>
        <TextField
          label="Título da Vaga"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
          required
          fullWidth
          InputLabelProps={{ style: { fontWeight: 'bold' } }}
        />
        <EditorQuill
          ref={quillRef}
          value={description}
          onChange={(value) => setDescription(value)}
        />
        <TextField
          label="Localização"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          required
          fullWidth
          InputLabelProps={{ style: { fontWeight: 'bold' } }}
        />
        <TextField
          label="Categoria"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          select
          required
          fullWidth
          InputLabelProps={{ style: { fontWeight: 'bold' } }}
        >
          {categories.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <div className="file-upload-section">
          <Button variant="contained" component="label" className="upload-button">
            Upload Anexo
            <input
              type="file"
              hidden
              onChange={(e) => setAttachment(e.target.files[0])}
            />
          </Button>
          <Button variant="contained" component="label" className="upload-button">
            Upload Foto
            <input
              type="file"
              hidden
              onChange={(e) => setPhoto(e.target.files[0])}
            />
          </Button>
        </div>
        <Button type="submit" variant="contained" color="primary" disabled={uploading} className="submit-button">
          {uploading ? 'Postando...' : 'Postar Vaga'}
        </Button>
      </form>
    </div>
  );
};

export default UploadJob;
