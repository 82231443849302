import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../style/feed.css';
import { mUser } from '../utils/utils';

const FeedItem = ({ id, type, user, time, content, image, details, onDelete, onReport }) => {
  const [showFullContent, setShowFullContent] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  }

  const truncatedContent = content.length > 100 ? content.substring(0, 100) + '...' : content;

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  const handleDelete = () => {
    onDelete && onDelete(id, type);
    setShowMenu(false);
  };

  const handleReport = () => {
    onReport && onReport(id);
    setShowMenu(false);
  };

  return (
    <div className="feed-item">
      <div className="feed-header">
        <img src={user.avatar} alt={user.name} className="feed-avatar" />
        <div className="feed-user-info">
          <h3>{user.name}
            <small className='bg-primary text-white' style={{ padding: '2px', borderRadius: '6px' }}>{type}</small>
          </h3>
          <p>{time}</p>
        </div>
        <div className="menu-wrapper">
          <button
            className="menu-button"
            onClick={handleMenuToggle}
            aria-haspopup="true"
            aria-expanded={showMenu}
          >
            ⋮
          </button>
          {showMenu && (
            <div className="menu">
              <button onClick={handleReport}>Denunciar</button>
              {user.id === mUser.uid && <button onClick={handleDelete}>Eliminar</button>}
            </div>
          )}
        </div>
      </div>
      <Link to={`/post/${id}`} className="feed-content" style={{textDecoration:'none', color:'#000'}}>
        <div>
          {showFullContent ? (
                            <div className="card-text" dangerouslySetInnerHTML={{ __html: content }} />
                          ) : (
            <p><div dangerouslySetInnerHTML={{ __html: truncatedContent }} /></p>
          )}
          {content.length > 100 && (
            <b onClick={toggleContent} className="show-more-button">
              {showFullContent ? 'Exibir menos' : 'Exibir mais'}
            </b>
          )}
        </div>
        {image && (
          <a href={image} target='_blank' rel="noopener noreferrer">
            <img src={image} alt="Feed Content" className="feed-image" />
          </a>
        )}
        {type === 'job' && (
          <div className="feed-details">
            <p><strong>Localização:</strong> {details.location}</p>
            <p><strong>Categoria:</strong> {details.category}</p>
          </div>
        )}
        {type === 'cotation' && (
          <div className="feed-details">
            <p><strong>Descricao:</strong> {details.title}</p>
            <p><strong>Data de Entrega:</strong> {details.dueDate}</p>
          </div>
        )}
      </Link>
    </div>
  );
};

export default FeedItem;
