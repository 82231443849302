import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../style/cotation.css';
import { database } from '../firebase';
import { onValue, ref } from 'firebase/database';

const Concursos = () => {
    const [concursos, setConcursos] = useState([]);

    useEffect(() => {
        const concursosRef = ref(database, 'concursos/');
        onValue(concursosRef, (snapshot) => {
            const concursosData = snapshot.val();
            if (concursosData) {
                const concursosArray = Object.entries(concursosData).map(([id, data]) => ({ id, ...data }));
                setConcursos(concursosArray);
            }
        });
    }, []);

    return (
        <div className="container mt-4">
            <div className="row">
                {concursos.map((concurso) => (
                    <div key={concurso.id} className="col-md-4">
                        <div className="card mb-4 shadow-sm">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-2 d-flex align-items-center">
                                        <img src={concurso.logo} alt={`${concurso.organizer} logo`} className="img-fluid" />
                                    </div>
                                    <div className="col-md-10">
                                        <h5 className="card-title">{concurso.title}</h5>
                                        <h6 className="card-subtitle mb-2 text-muted">
                                            {concurso.organizer} - {concurso.location}
                                        </h6>
                                        <p className="card-text">{concurso.description}</p>
                                        <div className="d-flex justify-content-between">
                                            <small className="text-muted">Publicado em: {concurso.publishedDate}</small>
                                            {concurso.isExpired ? (
                                                <span className="badge bg-danger">Expirado</span>
                                            ) : (
                                                <span className="badge bg-success">Ativo</span>
                                            )}
                                        </div>
                                        <Link to={`/concurso/${concurso.id}`} className="btn btn-primary mt-2">
                                            Ver Detalhes
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Concursos;
