import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, TextField, Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { ref, get, set } from 'firebase/database';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { database, storage } from '../firebase';
import { mCompany, mUser } from '../utils/utils';

const CotationDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [cotation, setCotation] = useState(null);
  const [proposal, setProposal] = useState("");
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  // Add state for user and company
  const [user, setUser] = useState(null);
  const [companyName, setCompanyName] = useState('');

  // State for modal
  const [openModal, setOpenModal] = useState(false);
  const [viewResponse, setViewResponse] = useState(null); // State to hold the response

  useEffect(() => {
    if (mUser && mCompany) {
      setUser(mUser);
      setCompanyName(mCompany.nome);
    } else {
      console.error("User or Company data is missing");
    }
  }, []);

  useEffect(() => {
    const cotationRef = ref(database, `cotations/${id}`);
    get(cotationRef).then((snapshot) => {
      if (snapshot.exists()) {
        setCotation(snapshot.val());
        // Check if the logged-in user has already submitted a proposal
        const proposals = snapshot.val().proposals;
        if (proposals && proposals[user.uid]) {
          setViewResponse(proposals[user.uid]);
        }
      } else {
        console.error("Cotação não encontrada");
      }
    }).catch((error) => {
      console.error("Erro ao buscar cotação:", error);
    });
  }, [id, user]);

  const handleProposalChange = (e) => {
    setProposal(e.target.value);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmitProposal = () => {
    if (!user) {
      alert("Usuário não autenticado. Por favor, faça login.");
      return;
    }

    if (proposal.trim() === "" && !file) {
      alert("Por favor, insira uma proposta ou carregue um documento.");
      return;
    }

    setUploading(true);

    if (file) {
      const storageReference = storageRef(storage, `proposals/${file.name}`)
      const uploadTask = uploadBytesResumable(storageReference, file)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          setUploadProgress(progress)
        },
        (error) => {
          console.error("Erro ao carregar o arquivo:", error);
          setUploading(false)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            submitProposal(downloadURL)
          });
        }
      );
    } else {
      submitProposal(null)
    }
  }

  const submitProposal = (fileUrl) => {
    const proposalsRef = ref(database, `cotations/${id}/proposals/${user.uid}`)
    const newProposal = {
      cotationId: id,
      company: companyName,
      nuit: mCompany.nuit,
      proposal: proposal,
      fileUrl: fileUrl,
      submittedAt: new Date().toISOString(),
      status: 'wait'
    };
    set(proposalsRef, newProposal)
      .then(() => {
        console.log("Proposta submetida:", newProposal);
        setUploading(false);
        navigate('/');
      })
      .catch((error) => {
        console.error("Erro ao submeter a proposta:", error);
        setUploading(false);
      });
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleViewResponse = () => {
    if (viewResponse) {
      // Open the response in a new tab or modal
      window.open(viewResponse.fileUrl, '_blank');
    }
  };

  if (!cotation) {
    return <div className="container mt-4">Carregando...</div>;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8">
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">Empresa: {cotation.company}</h5>
              <p className="card-text">Endereço de Entrega: {cotation.deliveryAddress}</p>
              <p className="card-text">Termos de Entrega: {cotation.deliveryTerms}</p>
              <p className="card-text">Data Limite: {cotation.dueDate}</p>
              <p className="card-text">Data de Emissão: {cotation.issueDate}</p>
              <p className="card-text">Valor Máximo: {cotation.maxValue}</p>
              <p className="card-text">Termos de Pagamento: {cotation.paymentTerms}</p>
              <p className="card-text">Comentários Adicionais: {cotation.additionalComments}</p>
              <p className="card-text">Critérios de Seleção: {cotation.selectionCriteria}</p>
              <p className="card-text">Garantia: {cotation.warranty}</p>
              <Button variant="contained" color="primary" onClick={handleOpenModal}>
                Ver Produtos
              </Button>
              {viewResponse && (
                <Button variant="contained" color="secondary" onClick={handleViewResponse}>
                  Ver Resposta
                </Button>
              )}
              {/* Modal for products */}
              <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
                <DialogTitle>Detalhes dos Produtos</DialogTitle>
                <DialogContent>
                  {cotation.products.map((product, index) => (
                    <div key={index} className="mb-3">
                      <Typography variant="body1"><strong>Código do Produto:</strong> {product.productCode}</Typography>
                      <Typography variant="body1"><strong>Descrição do Produto:</strong> {product.productDescription}</Typography>
                      <Typography variant="body1"><strong>Quantidade:</strong> {product.quantity}</Typography>
                      <Typography variant="body1"><strong>Especificações Técnicas:</strong> {product.technicalSpecifications}</Typography>
                      <Typography variant="body1"><strong>Requisitos de Embalagem:</strong> {product.packagingRequirements}</Typography>
                      <Typography variant="body1"><strong>Tempo de Entrega:</strong> {product.deliveryTime}</Typography>
                      <Typography variant="body1"><strong>Unidade:</strong> {product.unit}</Typography>
                      <hr />
                    </div>
                  ))}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseModal} color="primary">
                    Fechar
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card mb-3">
            <div className="card-body">
              <TextField
                className="form-control mb-2"
                placeholder="Proposta"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                value={proposal}
                onChange={handleProposalChange}
              />
              <div className="mb-2">
                <label htmlFor="fileInput" className="btn btn-secondary">
                  Anexar
                  <input
                    type="file"
                    id="fileInput"
                    className="d-none"
                    onChange={handleFileChange}
                  />
                </label>
              </div>
              {uploading && (
                <div className="d-flex align-items-center mb-2">
                  <CircularProgress variant="determinate" value={uploadProgress} />
                </div>
              )}
              <div className="d-flex justify-content-between">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitProposal}
                  disabled={uploading}
                >
                  Responder
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CotationDetails;
