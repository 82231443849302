import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, set, get } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { database, auth } from '../firebase';
import '../style/profile.css';

const EditProfilePage = () => {
  const navigate = useNavigate();
  const [company, setCompany] = useState(null);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [contact, setContact] = useState('');
  const [logo, setLogo] = useState(null);
  const [facebook, setFacebook] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [instagram, setInstagram] = useState('');
  const [website, setWebsite] = useState('');
  const [legalDoc, setLegalDoc] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompanyData = async () => {
      const companyRef = ref(database, `company/${auth.currentUser.uid}`);
      const snapshot = await get(companyRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        setCompany(data);
        setName(data.nome || '');
        setAddress(data.endereco || '');
        setContact(data.contacto || '');
        setFacebook(data.social?.facebook || '');
        setLinkedin(data.social?.linkedin || '');
        setInstagram(data.social?.instagram || '');
        setWebsite(data.social?.website || '');
        setLoading(false);
      } else {
        setLoading(false);
        navigate('/profile');
      }
    };

    fetchCompanyData();
  }, [navigate]);

  const handleSave = () => {
    const companyRef = ref(database, `company/${auth.currentUser.uid}`);
    const socialRef = ref(database, `company/${auth.currentUser.uid}/social`);
    const legalDocRef = ref(database, `company/${auth.currentUser.uid}/legalDoc`);

    // Dados principais da empresa
    const companyData = {
      nome: name,
      endereco: address,
      contacto: contact,
      logoUrl: company?.logoUrl || ''
    };

    // Dados das redes sociais
    const socialData = {
      facebook: facebook,
      linkedin: linkedin,
      instagram: instagram,
      website: website
    };

    // Atualizar dados da empresa
    set(companyRef, companyData).then(() => {
      if (logo) {
        const storage = getStorage();
        const fileRef = storageRef(storage, `profile/${auth.currentUser.uid}/logo`);
        uploadBytes(fileRef, logo).then(() => {
          getDownloadURL(fileRef).then((url) => {
            set(ref(database, `company/${auth.currentUser.uid}/logoUrl`), url);
          }).catch((error) => console.error('Error getting logo URL:', error));
        }).catch((error) => console.error('Error uploading logo:', error));
      }

      // Atualizar dados das redes sociais
      set(socialRef, socialData).catch((error) => console.error('Error saving social data:', error));

      if (legalDoc) {
        const storage = getStorage();
        const docRef = storageRef(storage, `legal/${auth.currentUser.uid}/${legalDoc.name}`);
        uploadBytes(docRef, legalDoc).then(() => {
          getDownloadURL(docRef).then((url) => {
            set(ref(database, `company/${auth.currentUser.uid}/legalDoc`), url);
          }).catch((error) => console.error('Error getting document URL:', error));
        }).catch((error) => console.error('Error uploading document:', error));
      }

      navigate('/profile');
    }).catch((error) => console.error('Error saving profile:', error));
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="edit-profile-container">
      <h2>Edit Profile</h2>
      <div className="form-group">
        <label>Company Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Address:</label>
        <input
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Contact:</label>
        <input
          type="text"
          value={contact}
          onChange={(e) => setContact(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Facebook:</label>
        <input
          type="text"
          value={facebook}
          onChange={(e) => setFacebook(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>LinkedIn:</label>
        <input
          type="text"
          value={linkedin}
          onChange={(e) => setLinkedin(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Instagram:</label>
        <input
          type="text"
          value={instagram}
          onChange={(e) => setInstagram(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Website:</label>
        <input
          type="text"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Logo:</label>
        <input
          type="file"
          onChange={(e) => setLogo(e.target.files[0])}
        />
      </div>
      <div className="form-group">
        <label>Legal Document:</label>
        <input
          type="file"
          onChange={(e) => setLegalDoc(e.target.files[0])}
        />
      </div>
      <button onClick={handleSave}>Save Changes</button>
      <button onClick={() => navigate('/profile')}>Cancel</button>
    </div>
  );
};

export default EditProfilePage;
