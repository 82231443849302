import React, { useEffect, useState } from 'react';
import { ref, onValue, off, remove } from 'firebase/database';
import { database } from '../firebase';
import '../style/home.css';
import Hero from '../components/Hero';
import Feed from '../components/Feed';
import CompanyStorie from '../components/CompanyStorie';

const Home = () => {
  const [feedData, setFeedData] = useState([]);

  useEffect(() => {
    const fetchJobsAndCotations = () => {
      const jobsRef = ref(database, 'jobs');
      const cotationsRef = ref(database, 'cotations');

      onValue(jobsRef, (snapshot) => {
        const jobs = snapshot.val();
        if (jobs) {
          const jobsArray = Object.keys(jobs).map((key) => ({
            id: key, 
            type: 'job',
            user: { name: jobs[key].companyName, avatar: jobs[key].companyLogo, id: jobs[key].companyId },
            time: jobs[key].postedAt,
            content: jobs[key].description,
            image: jobs[key].photoUrl,
            details: {
              location: jobs[key].location,
              category: jobs[key].category,
            },
          }));
          setFeedData((prevData) => [...prevData, ...jobsArray]);
        }
      });

      onValue(cotationsRef, (snapshot) => {
        const cotations = snapshot.val();
        if (cotations) {
          const cotationsArray = Object.keys(cotations).map((key) => ({
            id: key, 
            type: 'cotation',
            user: { name: cotations[key].company, avatar: cotations[key].companyPic, id: cotations[key].user },
            time: cotations[key].dueDate,
            content: cotations[key].additionalComments,
            image: cotations[key].files && cotations[key].files.length > 0 ? cotations[key].files[0] : null,
            details: {
              company: cotations[key].company,
              dueDate: cotations[key].dueDate,
              title: cotations[key].title,
            },
          }));
          setFeedData((prevData) => [...prevData, ...cotationsArray]);
        }
      });
    };

    fetchJobsAndCotations();

    return () => {
      off(ref(database, 'jobs'));
      off(ref(database, 'cotations'));
    };
  }, []);

  const handleDelete = (id, type) => {
    const postRef = ref(database, `${type}s/${id}`);
    remove(postRef)
      .then(() => {
        setFeedData((prevData) => prevData.filter((post) => post.id !== id));
      })
      .catch((error) => {
        console.error("Erro ao deletar o post:", error);
      });
  };

  return (
    <>
      <CompanyStorie/>
      <Hero />
      <Feed feedData={feedData} onDelete={handleDelete} />
    </>
  );
};

export default Home;
