import React, { useEffect, useState, useMemo } from 'react';
import { ref, onValue, update } from 'firebase/database';
import { database } from '../firebase';


const CotationInbox = () => {
  const [quotations, setQuotations] = useState([]);
  const [loading, setLoading] = useState(true);

  const company = useMemo(() => {
    const companyData = localStorage.getItem('company');
    return companyData ? JSON.parse(companyData) : null;
  }, []);

  useEffect(() => {
    if (!company || !company.id) {
      console.error('Company not found or not logged in');
      return;
    }

    const quotationsRef = ref(database, 'quotation_requests');
    const unsubscribe = onValue(quotationsRef, (snapshot) => {
      const data = snapshot.val();
      const companyQuotations = [];
      if (data) {
        Object.values(data).forEach((quotation) => {
          if (quotation.to === company.id) {
            companyQuotations.push(quotation);
          }
        });
      }
      setQuotations(companyQuotations);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [company]);

  const handleAction = (quotationId, action) => {
    const updatedQuotation = { ...quotations.find(q => q.id === quotationId) };
    updatedQuotation.status = action;

    const quotationRef = ref(database, `quotation_requests/${quotationId}`);
    update(quotationRef, { status: action })
      .then(() => {
        console.log(`Quotation ${quotationId} updated successfully with action: ${action}`);
      })
      .catch((error) => {
        console.error('Error updating quotation:', error);
      });
  };
  return (
    <div>
      <h2>Pedidos de Cotação Recebidos</h2>
      {quotations.length === 0 ? (
        <p>Nenhum pedido de cotação encontrado.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Empresa</th>
              <th>Itens</th>
              <th>Notas</th>
              <th>Status</th>
              <th>Data</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(quotations) && quotations.map((quotation, index) => (
              <tr key={index}>
                <td>{quotation.company}</td>
                <td>
                  {Array.isArray(quotation.items) && quotation.items.map((item, idx) => (
                    <div key={idx}>
                      {item.name} (Quantidade: {item.quantity})
                    </div>
                  ))}
                </td>
                <td>{quotation.notes}</td>
                <td>{quotation.status}</td>
                <td>{new Date(quotation.timestamp).toLocaleString()}</td>
                <td>
                  <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" id={`dropdownMenuButton-${index}`} data-bs-toggle="dropdown" aria-expanded="false">
                      Ações
                    </button>
                    <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${index}`}>
                      <li><button className="dropdown-item" onClick={() => handleAction(quotation.timestamp, 'permitir_proforma')}>Permitir Proforma</button></li>
                      <li><button className="dropdown-item" onClick={() => handleAction(quotation.timestamp, 'rejeitar_pedido')}>Rejeitar Pedido</button></li>
                      <li><button className="dropdown-item" onClick={() => handleAction(quotation.timestamp, 'emitir_proforma')}>Emitir Proforma</button></li>
                    </ul>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CotationInbox;
